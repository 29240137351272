import React, { Component } from 'react';
import styled from '@emotion/styled';
import Image from 'gatsby-image';

import * as utils from '@utils';
import { respondFrom, breakpoints, css, dimensions } from '@styles';
import SectionOverlapping from '@components/common/SectionOverlapping';
import Container from '@components/layout/Container';
import { BranchSectionWhatisProps } from '@content/types/branch';

import AnimationTriggerWaypoint from '@animations/AnimationTriggerWaypoint';
import AnimationCircle from '@animations/AnimationCircle';

const StyledContent = styled.div`
  h3 {
    font-size: 40px !important;
    line-height: 1.3;
  }
`;

const StyledSectionOverlapping = styled(SectionOverlapping)`
  ${respondFrom(
    breakpoints.lg,
    css`
      margin-bottom: 150px;
    `
  )}
`;

const StyledSectionIcons = styled.ul`
  list-style: none;
  margin: 0 -5px;
  padding: 0;
  font-size: 16px;
  line-height: 1.5;

  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  li {
    text-align: center;
    align-self: flex-start;
    padding: 0 5px;

    img {
      margin-bottom: 0.2em;
    }

    p {
      font-size: ${dimensions.fontSize.smaller}px;

      ${respondFrom(
        450,
        css`
          font-size: ${dimensions.fontSize.regular}px;
        `
      )}
    }
  }
`;

const AnimationCircleWrapper = styled.div`
  position: absolute;
  top: -100px;
  right: -400px;
  transform-origin: center;
  /* transform: rotate(270deg); */
`;

interface Props extends BranchSectionWhatisProps {
  isVisible: boolean;
}

class BranchWhatIsIpos extends Component<Props> {
  state = {
    isVisible: false,
  };

  render() {
    const { props } = this;

    return (
      <AnimationTriggerWaypoint onChange={() => this.setState({ isVisible: true })}>
        <Container>
          <AnimationCircleWrapper>
            <AnimationCircle
              sectionVisible={this.state.isVisible}
              className="size-large"
              animateReverse={true}
            />
          </AnimationCircleWrapper>
          <StyledSectionOverlapping
            imageContent={props.image && <Image {...props.image.childImageSharp} />}
            content={
              <StyledContent>
                <h3>{props.title}</h3>
                <div>{utils.SafeHtml(props.paragraph)}</div>
              </StyledContent>
            }
            contentNoBg={
              <StyledSectionIcons>
                {!!props.icon1 && (
                  <li>
                    <img src={props.icon1.publicURL} alt="" />
                    <span>{utils.SafeHtml(props.text1 || '')}</span>
                  </li>
                )}
                {!!props.icon2 && (
                  <li>
                    <img src={props.icon2.publicURL} alt="" />
                    <span>{utils.SafeHtml(props.text2 || '')}</span>
                  </li>
                )}
                {!!props.icon3 && (
                  <li>
                    <img src={props.icon3.publicURL} alt="" />
                    <span>{utils.SafeHtml(props.text3 || '')}</span>
                  </li>
                )}
              </StyledSectionIcons>
            }
            lineAfter={true}
          />
        </Container>
      </AnimationTriggerWaypoint>
    );
  }
}

export default BranchWhatIsIpos;
