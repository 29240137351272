import React from 'react';
import styled from '@emotion/styled';
import { colors, dimensions, respondFrom, breakpoints, css, fluidRange, mixins } from '@styles';
import ButtonLink from '@components/common/ButtonLink';
import Collapsible from 'react-collapsible';
import BadgeRecommended from '@components/common/BadgeRecommended';
import * as utils from '@utils';

import IconTick from '@assets/svg/feature-incl-icon.svg';
import IconClose from '@assets/svg/feature-notincl-icon.svg';

const TariffsWrapper = styled.div``;

const TariffsTitle = styled.div`
  ${respondFrom(
    breakpoints.lg,
    css`
      text-align: center;
      max-width: 770px;
      margin: auto;
      margin-top: 100px;
    `
  )}
`;

const TariffsList = styled.div`
  display: flex;
  flex-flow: column;
  position: relative;
  padding-bottom: 60px;
  margin-top: 30px;

  ${respondFrom(
    breakpoints.lg,
    css`
      flex-flow: row;
      justify-content: center;
      max-width: 940px;
      margin: 40px auto 0;
    `
  )}

  a {
    position: absolute;
    right: 10px;
    bottom: 0;
  }
`;

const TariffItem = styled.div`
  position: relative;
  background-color: ${colors.white};
  border: 1px solid ${colors.gray};
  border-top: 0;
  flex: 1;

  &:nth-of-type(1) {
    border-top: 1px solid ${colors.gray};
  }

  ${respondFrom(
    breakpoints.lg,
    css`
      max-width: 215px;
      margin: 0 10px;
      padding-bottom: 40px;
      border: 1px solid ${colors.gray};
    `
  )}

  .Collapsible {
    &__trigger {
      position: relative;
      display: block;
      padding: 24px 21px 20px;
      border-bottom: 1px solid ${colors.gray};
      font-weight: 100;
      font-size: ${fluidRange('fontSize', '25px', '30px')};

      &::before {
        content: '';
        position: absolute;
        top: -5px;
        bottom: 0;
        right: 30px;
        margin: auto;
        width: 10px;
        height: 10px;
        border: 2px solid ${colors.green.dark};
        border-left: 0;
        border-top: 0;
        transform: rotate(45deg);
        transform-origin: center;
        ${mixins.transitionDefault};
      }

      &.is-open {
        &::before {
          transform: translate(0, 5px) rotate(225deg);
        }
      }

      &.is-closed {
        border-bottom: transparent;
      }

      ${respondFrom(
        breakpoints.lg,
        css`
          &::before {
            content: none;
          }

          &.is-closed {
            border-bottom: 1px solid ${colors.gray};
          }
        `
      )}
    }

    &__contentOuter {
      ${respondFrom(
        breakpoints.lg,
        css`
          height: auto !important;
        `
      )}
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 22px 20px 14px;

    li {
      font-size: ${dimensions.fontSize.small}px;
      position: relative;
      padding-left: 20px;
      margin: 15px 0;

      &.disabled {
        color: ${colors.ui.light};
      }

      img {
        position: absolute;
        top: 2px;
        left: 0;
      }
    }
  }

  &.background-dark {
    background-color: ${colors.grayNurse};
    .Collapsible__trigger {
      background-color: ${colors.white};
    }
  }

  &.recommended {
    ${respondFrom(
      breakpoints.lg,
      css`
        margin-top: -10px;
        margin-bottom: -10px;
        padding-bottom: 50px;

        .Collapsible__trigger {
          padding-top: 34px;
        }
      `
    )}
  }
`;

const TariffPrice = styled.p`
  font-size: ${dimensions.fontSize.small}px;
  padding: 15px 20px;

  ${respondFrom(
    breakpoints.lg,
    css`
      position: absolute;
      left: 20px;
      right: 20px;
      bottom: 0;
      padding: 0;

      &.recommended {
        bottom: 10px;
      }
    `
  )}
`;

const StyledBadgeRecommended = styled.div`
  position: absolute;
  top: -20px;
  left: 0;

  ${respondFrom(
    breakpoints.lg,
    css`
      top: -30px;
    `
  )}
`;

interface TariffsProps {
  title?: string;
  subtitle?: string;
  buttonLabel: string;
  tariffs: TariffDetail[];
  pricePeriod?: string;
  compareButtonLabel?: string;
  backgroundDark?: boolean;
  langcode: string;
  urls: Array<string>;
  hidePrice?: boolean;
}

interface TariffDetail {
  name: string;
  price: string | number;
  profits: Profits[];
  recommended?: boolean;
}

interface Profits {
  name: string;
  active: boolean;
}

const Tariffs = (props: TariffsProps) => {
  return (
    <TariffsWrapper>
      <TariffsTitle>
        {props.title && props.title !== '' && <h3>{utils.SafeHtml(props.title)}</h3>}
        {props.subtitle && props.subtitle !== '' && utils.SafeHtml(props.subtitle)}
        <ButtonLink
          to={utils.langLink(props.langcode, props.urls[12])}
          color="text"
          icon="arrow-right"
        >
          {props.buttonLabel}
        </ButtonLink>
      </TariffsTitle>
      <TariffsList>
        {props.tariffs.map((tariff, index) => (
          <TariffItem
            key={index}
            className={`
            ${props.backgroundDark ? 'background-dark' : ''}
            ${tariff.recommended ? 'recommended' : ''}
          `}
          >
            {tariff.recommended && (
              <StyledBadgeRecommended>
                <BadgeRecommended />
              </StyledBadgeRecommended>
            )}
            <Collapsible trigger={tariff.name}>
              <ul>
                {tariff.profits.map((profit, i) => (
                  <li className={!profit.active ? 'disabled' : ''} key={i}>
                    <img src={profit.active ? IconTick : IconClose} alt="" />
                    {utils.SafeHtml(profit.name)}
                  </li>
                ))}
              </ul>
              {!props.hidePrice ? (
                <TariffPrice className={tariff.recommended ? 'recommended' : ''}>
                  {props.pricePeriod && props.pricePeriod !== ''
                    ? `${Math.round(Number(tariff.price))} ${props.pricePeriod}`
                    : tariff.price}
                </TariffPrice>
              ) : null}
            </Collapsible>
          </TariffItem>
        ))}

        {props.compareButtonLabel && props.compareButtonLabel !== '' && (
          <ButtonLink
            to={utils.langLink(props.langcode, props.urls[1])}
            color="text"
            icon="arrow-right"
          >
            {props.compareButtonLabel}
          </ButtonLink>
        )}
      </TariffsList>
    </TariffsWrapper>
  );
};

export default Tariffs;
